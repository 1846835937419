import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
  UploadFilesForm,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useContext, useState } from 'react';
import {
  CaseFlowCaseEditingID,
  useCaseFlowCase,
  useFileStorage,
} from '@rabbit/bizproc/react';
import { UserUploadedDocument } from '@rabbit/data/types';
import { useMyConsumerPersona } from '@rabbit/data/portal';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';

export interface LogPostageModalProps {
  handleClose: () => void;
}

interface FormValuesShape {
  tracking_number: string;
  carrier: string;
}

const initialValues = {
  tracking_number: '',
  carrier: '',
};

const validationSchema = Yup.object().shape({
  tracking_number: Yup.string().trim(),
  carrier: Yup.string().trim(),
});

export function LogPostageModal({ handleClose }: LogPostageModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { personaId } = useMyConsumerPersona();
  const { id } = useParams();
  const caseId: CaseFlowCaseEditingID = {
    type: 'cfc' as const,
    case: id ?? ('' as const),
    operating_persona: personaId ?? '',
    principal_persona: personaId ?? '', // DEV-502, Consumer is acting on their own behalf here (uploading proof of postage after sending item to repairer).
    isNewCase: false,
  };
  const { caseFlowCase } = useCaseFlowCase(caseId);
  const {
    uploadFiles,
    isUpdating,
    uploadProgress,
    deleteFile,
    uploadTaskCat,
    uploadedTempFiles,
    updateHoldingWithFiles,
    updateVendableWithFiles,
    clearFileFromHolding,
    clearFileFromVendable,
    clearAllTempFilesFromState,
    clearFileFromState,
    deleteUnusedTempFiles,
  } = useFileStorage();

  const caseActors = caseFlowCase?.GetActors();
  const caseFacts = caseFlowCase?.GetAllFacts();
  const [uploadedProofOfPostage, setUploadedProofOfPostage] = useState(
    caseFacts?.postage_receipts
  );

  const onSubmit = async (values: FormValuesShape) => {
    const { tracking_number, carrier } = values;
    setIsSubmitting(true);

    await caseFlowCase?.Alter_CaseSpotlight_Clear();

    caseFlowCase?.Alter_Facts({
      outbound_carrier: carrier ? carrier : '-',
      outbound_tracking_number: tracking_number ? tracking_number : '-',
    });
    await caseFlowCase?.Commit();
    setIsSubmitting(false);
    handleClose();
  };

  const onLabelUploaded = async (files: UserUploadedDocument[]) => {
    if (files && files.length && caseFlowCase) {
      clearAllTempFilesFromState();
      const parsedFiles = JSON.parse(JSON.stringify(files));
      caseFlowCase?.Alter_Facts({
        postage_receipts: parsedFiles?.length ? parsedFiles : [],
      });
      await caseFlowCase.Commit();
      setUploadedProofOfPostage(files);
      toast.success(t('Proof of postage added successfully'));
    }
  };

  const modalSettings = {
    title: t('Log postage'),
    handleClose: () => handleClose(),
  };

  return (
    <Modal
      kind="generic"
      settings={modalSettings}
      isLoading={isSubmitting}
      className="m-auto w-[724px] rounded-md border bg-white"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {() => (
          <Form className="mt-5 flex flex-col gap-3 px-4">
            <Input
              type="text"
              label={t('Tracking number')}
              name="tracking_number"
              settings={{
                id: 'tracking_number',
                placeholder: t('Enter the tracking number'),
              }}
            />
            <Input
              type="text"
              label="Carrier"
              name="carrier"
              settings={{
                id: 'carrier',
                placeholder: t('Enter the carrier name'),
              }}
            />
            <UploadFilesForm
              label={t('Proof of postage')}
              type="case"
              docId={id || ''}
              personaId={caseActors?.consumer ?? ''}
              currentFiles={uploadedProofOfPostage ?? []}
              onFilesUploaded={onLabelUploaded}
              uploadHandlers={{
                uploadedTempFiles,
                category: 'postage_receipts',
                uploadFiles,
                uploadTaskCat,
                isUpdating,
                uploadProgress,
                deleteFile,
                updateHoldingWithFiles,
                updateVendableWithFiles,
                clearFileFromHolding,
                clearFileFromVendable,
                clearFileFromState,
                deleteUnusedTempFiles,
              }}
            />

            <div className="mt-4 flex flex-col gap-4">
              <Button kind="primary" type="submit" loading={isSubmitting}>
                {t('Log postage')}
              </Button>
              <Button
                kind="outline"
                type="button"
                className="w-full"
                onClick={() => handleClose()}
              >
                {t('I’ll fill the details later')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default LogPostageModal;
